import React, { useState } from 'react';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { CiLocationOn } from "react-icons/ci";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { MdBookmarkBorder } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import { IoMdAlarm } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import DropdownReminder from '../DropdownReminder/DropdownReminder';
import { Modal, Button } from 'react-bootstrap';


export const jobProfiles = [
  {
    id: 1,
    name: 'Edward',
    image: 'https://i.pravatar.cc/150?img=3',
    experience: '5yrs 6m',
    education: 'B.Sc. in Computer Science',
    skills: 'JavaScript, React, Node.js',
    location: 'New York, USA',
    salary: '$8,6440',
    date: 'Today'
  },
  {
    id: 2,
    name: 'Jonathan',
    image: 'https://i.pravatar.cc/150?img=4',
    experience: '3yrs 2m',
    education: 'B.A. in Design',
    skills: 'Devops, Java, AWS, HTML5, Scss',
    location: ' , USA',
    salary: '$6,8440',
    date: 'Yesterday'
  },

  {
    id: 3,
    name: 'Benjamin',
    image: 'https://i.pravatar.cc/150?img=8',
    experience: '8yrs 2m',
    education: 'BS in CS, IT, relevant Engineering field',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'California, USA',
    salary: '$8,5940',
    date: 'Yesterday'
  },

  // Add more profiles as needed
];

function JobProfileList() {

  const [showModal, setShowModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [comment, setComment] = useState('');

  const handleClose = () => setShowModal(false);
  const handleShow = (profile) => {
    setSelectedProfile(profile);
    setShowModal(true);
  };

  const handleSaveComment = () => {
    alert(`Comment for ${selectedProfile.name}: ${comment}`);
    setShowModal(false);
    setComment('');
  };


  const options = [
    { value: 'option1', label: 'For Call Later' },
    { value: 'option2', label: 'For Interview Follow up' },
    { value: 'option3', label: 'For Sending JD' },
    { value: 'option4', label: 'For Other Task' },
];

const handleSelect = (option) => {
    alert(`Selected: ${option.label}`);
};

  const navigate = useNavigate();

  const handleProfileClick = (id) => {
    navigate(`/profile/${id}`);
  };





  return (
    <div className="container mb-4">
      <div className="row">
       {jobProfiles.map(profile => (
          <div className='col-12 card rounded-4 p-2 mb-4'  key={profile.id}
          >
            <div className="d-lg-flex js-pst align-items-stretch p-4" key={profile.id}>
              <div className='pe-2'>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                </div>
              </div>
              <div className='flex-grow-1'>
                <div className="me-3">
                  <h5 className="card-title" onClick={() => handleProfileClick(profile.id)}
          style={{ cursor: 'pointer' }}>{profile.name}</h5>
                  <span className="card-text">000{profile.id}</span>
                  <div className='d-lg-flex d-sm-block'>
                    <div className="card-text mt-3 me-3">
                      <LuWallet className="me-2" /> {profile.salary}
                    </div>
                    <div className="card-text mt-3 me-3">
                      <PiSuitcaseSimpleLight className="me-2" /> {profile.experience}
                    </div>
                    <div className="card-text mt-3">
                      <CiLocationOn className="me-2" /> {profile.location}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <HiOutlineAcademicCap className="me-2" /> {profile.education}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <IoSettingsOutline className="me-2" /> {profile.skills}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src={profile.image} className="img-thumbnail rounded-circle border-0" alt={profile.name} style={{ maxWidth: '80px' }} />
              </div>
            </div>
            <div className='d-lg-flex d-sm-block justify-content-between border-top border-bottom mx-4 mt-2'>
              <div className='py-3'>
                <label className='small'>Pref Locations</label>
                <p className="card-text pt-2">New York, Washington, California</p>
              </div>
              <div className='py-3'>
                <label className='small'>Notice Period</label>
                <p className="card-text pt-2">Less than 15 day’s</p>
              </div>
              <div className='py-3'>
                <p className="card-text pt-2">CV Updated 3 day’s ago</p>
              </div>
            </div>
            <div className='d-lg-flex d-sm-block align-content-center justify-content-between mx-4 mt-2'>
              <div className='py-3'>
              <button className='btn fs-4 p-0 m-0 icon-clr'><MdBookmarkBorder /></button>
              <button className='btn ms-3 small px-3 py-2 btn-outline-primary' onClick={() => handleShow(profile)}>Add Comment</button>
              </div>
              <div className='py-3'>
              <p className="card-text"><label className='small'>Seen</label> : 5 day’s ago   <button className='btn fs-4 ms-3 p-0 m-0 icon-clr'><CiMail /></button> <button className='btn fs-4 ms-2 p-0 m-0 icon-clr'><IoMdShare /></button>
              <DropdownReminder options={options} onSelect={handleSelect} />

              </p>

              </div>
            </div>
          </div>
        ))}
      </div>
            {/* Modal */}
            <Modal show={showModal} onHide={handleClose}>
            <Modal.Header  className="px-4" closeButton>
          <Modal.Title className="small">Add Comment for <br/><span className="fs-5 py-1 text-primary">{selectedProfile?.name}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-4">


        <ul class="timeline js-comment">
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel mb-4">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading  border p-4 rounded-4">
                        <p>This person not serving notice period and current package 24 lacks and expected salary 45 lacks</p>
                      </div>
                      <div className='d-flex justify-content-between'>
                      <div className='card-text text-star t pt-2 pb-3'><span className='text-primary small'>You</span></div>
                      <div className='card-text text-end pt-2 pb-3  small'>5 mins ago</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-badge"><span></span></div>
                  <div class="timeline-panel mb-4">
                    <div class="flex-container flex-around">
                      <div class="timeline-heading border p-4 rounded-4">
                        <p>We discussed again person currently serving notice period expected salary - 48 lacks </p>
                      </div>
                      <div className='d-flex justify-content-between'>
                      <div className='card-text text-star t pt-2 pb-3'><span className='text-primary small'>Madhav</span></div>
                      <div className='card-text text-end pt-2 pb-3  small'>2 mins ago</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

        </Modal.Body>
        <Modal.Footer className="p-4">

        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Add your comment here"
                            />
                            <button className="btn btn-primary">
                                Send
                            </button>
                        </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default JobProfileList;