// RelatedProfile.js
import React, { useEffect } from 'react';
import { Tooltip } from 'bootstrap';
import { Link } from 'react-router-dom';
import { LuWallet } from "react-icons/lu";
import { PiSuitcaseSimpleLight } from "react-icons/pi";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { GoLinkExternal } from "react-icons/go";
import { MdModeEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";

export const jobPosts = [
  {
    id: 1,
    jobtitle: 'UX Architect',
    company: 'Google',
    image: 'https://brandlogos.net/wp-content/uploads/2015/09/google-favicon-vector-400x400.png',
    experience: '5yrs 6m',
    education: 'B.Sc. in Computer Science',
    skills: 'JavaScript, React, Node.js',
    location: 'New York, USA',
    salary: '$9,1940',
  },
  {
    id: 2,
    jobtitle: 'Product Designer',
    company: 'Adobe',
    image: 'https://brandlogos.net/wp-content/uploads/2014/10/adobe-logo-2017-512x512.png',
    experience: '3yrs 2m',
    education: 'B.A. in Design',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'California, USA',
    salary: '$10,1940'
  },

  {
    id: 3,
    jobtitle: 'Sales Manager',
    company: 'Infosys',
    image: 'https://brandlogos.net/wp-content/uploads/2022/02/infosys-logo-brandlogos.net_-768x768.png',
    experience: '8yrs 2m',
    education: 'B.A. in Design',
    skills: 'Figma, Adobe XD, Wireframing',
    location: 'New York, USA',
    salary: '$15,1940'
  }
  // Add more profiles as needed
];

function ActivePost() {
  const navigate = useNavigate();

  const handleProfileClick = (id) => {
    navigate(`/job/${id}`);
  };


  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
    });
}, []);

  return (
    <div className="row">
      {jobPosts.map(job => (
        <div
          className='col-md-6 col-lg-4'
          key={job.id} >
          <div className='card rounded-4 p-2 mb-4'>
            <div className="d-lg-flex js-pst align-items-stretch p-4" key={job.id}>
              <div className='flex-grow-1'>
                <div className="me-3">
                  <h5 className="card-title" style={{ cursor: 'pointer' }} onClick={() => handleProfileClick(job.id)}>{job.jobtitle}</h5>
                  <span className="card-text">{job.company}</span>
                  <div className='d-lg-flex d-sm-block'>
                    <div className="card-text mt-3 me-4">
                      <LuWallet className="me-2" /> {job.salary}
                    </div>
                    <div className="card-text mt-3 me-0">
                      <PiSuitcaseSimpleLight className="me-2" /> {job.experience}
                    </div>

                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <HiOutlineAcademicCap className="me-2" /> {job.education}
                    </div>
                  </div>
                  <div className='d-flex mt-3'>
                    <div className="card-text">
                      <IoSettingsOutline className="me-2" /> {job.skills}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <img src={job.image} className="img-thumbnail rounded-circle border-0" alt={job.name} style={{ maxWidth: '60px' }} />
              </div>


            </div>


            <div className='d-flex align-content-center border-top justify-content-between mx-4 mt-2'>
            <div className='py-3 pb-1'>
              <p className="card-text small"><label>Posted on</label> : Dec 29th, 2024 </p>
              </div>
              <div className='py-3 pb-1'>
              <button className='btn p-0 m-0 btn-link me-2 text-decoration-none' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Active Post"><IoMdEye /></button>
              <Link to='/editjobpost' className='btn p-0 m-0 btn-link text-decoration-none' data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Edit Post"><MdModeEdit /></Link>
              </div>
            </div>
            <div className='d-flex align-content-center border-top justify-content-between mx-4 mt-2'>
              <div className='py-3'>
              <button className='btn p-0 m-0 small btn-link text-decoration-none'>Applied 96 <GoLinkExternal /></button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>

  );
}

export default ActivePost; // Default export
